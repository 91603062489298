import { Icon, SplitColumnsContainer, Tooltip } from '@startlibs/components'
import { ToggleCheckbox,  FormValue } from '@startlibs/form'
import { getColor, media } from '@startlibs/utils';
import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'
import { getIsVivaLite } from '../hooks/useIsVivaLite';
import { SpecialCheckbox } from '../pages/EditUser';

const WorklistPopupComponent = styled.div`
  position: absolute;
  background-color: #FFF;
  border: 1px solid ${getColor('gray210')};
  border-radius: 6px;
  padding: 1.25rem 1.25rem 0.25rem;
  z-index: 99;
  left: 0;
  top: 0;
  width: 100%;
  width: calc(100vw - 2rem);
  max-width: 100%;
  .upload-dateshortcuts {
    margin-top: -0.25rem;
    margin-bottom: 1rem;
    a {
      color: ${getColor('gray150')};
      margin-right: 1rem;
      text-decoration: underline;
    }
  }
  .popup-buttons-container {
    text-align: right;
  }
  strong {
    display: flex;
    align-items: center;
    ${Icon} {
      position: static;
      font-size: 22px;
      width: 2.5rem;
      line-height: 0;
      margin-left: -0.75rem;
      text-align: center;
    }
  }
`

export const WorklistToolsPopup = ({ closePopup, setUserTemplate }) => {
  const popupRef = React.useRef()
  const focusRef = useRef()

  useEffect(() => {
    const tryClose = (e) => !(popupRef.current.contains(e.target) || e.target === popupRef.current || e.defaultPrevented) && closePopup()
    document.addEventListener("click", tryClose)
    return () => document.removeEventListener("click", tryClose)
  }, [])

  useEffect(() => {
    if (focusRef.current) {
      focusRef.current.focus()
    }
  }, [])

  return <WorklistPopupComponent ref={popupRef} onKeyDown={({ key }) => key === "Enter"}
    onClick={(e) => e.stopPropagation()}>
    <h4><b>Worklist study tools</b></h4>
    <p>Select options available to this user</p>
    <div>
      <SplitColumnsContainer viewportMinWidth={850} >
        
        <Tooltip content={getIsVivaLite() && "Upgrade your Purview Image"}>
          <SpecialCheckbox
              label={<strong><Icon icon="view" />View on webviewer</strong>}
              path="worklistOptionsBoolean.viewStudy"
              onChange={() => { setUserTemplate() }}
              disabled={getIsVivaLite()}
          />
        </Tooltip>
        <Tooltip content={getIsVivaLite() && "Upgrade your Purview Image"}>
          <SpecialCheckbox
              label={<strong><Icon icon="link-thin" />Get webviewer link</strong>}
              path="worklistOptionsBoolean.viewerLink"
              onChange={() => { setUserTemplate() }}
              disabled={getIsVivaLite()}
          />
        </Tooltip>
      </SplitColumnsContainer>

      <SplitColumnsContainer viewportMinWidth={850} >
        <ToggleCheckbox
          label={<strong><Icon icon="download" />Download images</strong>}
          path="worklistOptionsBoolean.downloadStudy"
          onChange={() => { setUserTemplate() }}
        />
        <ToggleCheckbox
          label={<strong><Icon icon="download-cd" />Download for CD</strong>}
          path="worklistOptionsBoolean.downloadCD"
          onChange={() => { setUserTemplate() }}
        />
      </SplitColumnsContainer>

      <SplitColumnsContainer viewportMinWidth={850} >
        <ToggleCheckbox
          label={<strong><Icon icon="attachments" />Attachments</strong>}
          path="worklistOptionsBoolean.attachNonDicom"
          css="font-weight:600"
          onChange={() => { setUserTemplate() }}
        />
        <ToggleCheckbox
          label={<strong><Icon icon="study-notes" />Study notes</strong>}
          path="worklistOptionsBoolean.studyNotes"
          onChange={() => { setUserTemplate() }}
        />
      </SplitColumnsContainer>

      <SplitColumnsContainer viewportMinWidth={850} >
        <ToggleCheckbox
          label={<strong><Icon icon="report" />View report</strong>}
          path="worklistOptionsBoolean.report"
          onChange={() => { setUserTemplate("report") }}
        />
        <FormValue path='worklistOptionsBoolean.report'> 
          { report =>
            <ToggleCheckbox
              label={<strong><Icon icon="report" />Create report</strong>}
              className={!report ? "disabled" : " "}
              path="worklistOptionsBoolean.reportWriteAccess"
              onChange={() => { setUserTemplate() }}
            />
          }
        </FormValue>
      </SplitColumnsContainer>

      <SplitColumnsContainer viewportMinWidth={850} >
        <ToggleCheckbox
          label={<strong><Icon icon="send-line" />Share</strong>}
          path="worklistOptionsBoolean.share"
          onChange={() => { setUserTemplate() }}
        />
        <ToggleCheckbox
          label={<strong><Icon icon="unavailable" />Mark as read</strong>}
          path="worklistOptionsBoolean.markAsRead"
          onChange={() => { setUserTemplate() }}
        />
      </SplitColumnsContainer>
      <SplitColumnsContainer viewportMinWidth={850} >
        <ToggleCheckbox
          label={<strong><Icon icon="launch" />Launch in Horos</strong>}
          path="worklistOptionsBoolean.launchInHoros"
          onChange={() => { setUserTemplate() }}
        />
        <ToggleCheckbox
          label={<strong><Icon icon="edit-line" />Edit DICOM tags</strong>}
          path="worklistOptionsBoolean.dicomEdit"
          onChange={() => { setUserTemplate() }}
        />
      </SplitColumnsContainer>
      <SplitColumnsContainer viewportMinWidth={850} >
        <ToggleCheckbox
          label={<strong><Icon icon="change-patient" />Change patient</strong>}
          path="worklistOptionsBoolean.changePatient"
          onChange={() => { setUserTemplate() }}
        />
        <ToggleCheckbox
          label={<strong><Icon icon="route" />DICOM route</strong>}
          path="worklistOptionsBoolean.dicomRoute"
          onChange={() => { setUserTemplate() }}
        />
        {/* Use the code below as a spacer to fill up one column */}
        {/* <div style={{margin: '18px'}}></div> */}
      </SplitColumnsContainer>
      <SplitColumnsContainer viewportMinWidth={850} >
        <ToggleCheckbox
          label={<strong><Icon icon="delete" />Delete study</strong>}
          path="worklistOptionsBoolean.deleteStudy"
          onChange={() => { setUserTemplate() }}
        />
        {/* <div style={{margin: '18px'}}></div> */}
        <ToggleCheckbox
          disabled
          label={<strong><Icon icon="attachments" />See activity logs</strong>}
          path="worklistOptionsBoolean.activityLogs"
          css="font-weight:600; opacity: 0;"
          onChange={() => { setUserTemplate() }}
        />
        
      </SplitColumnsContainer>
      {/* 
          Activity logs is hidden via css and disabled. 
          This must be here because we did to set this permission on each study.
          As the permission is the same from menu pages (listed above), 
          changing the permission there will change it here
      */}
      {/* <SplitColumnsContainer viewportMinWidth={850} >
        <ToggleCheckbox
          disabled
          label={<strong><Icon icon="attachments" />See activity logs</strong>}
          path="worklistOptionsBoolean.activityLogs"
          css="font-weight:600; opacity: 0;"
          onChange={() => { setUserTemplate() }}
        />
      </SplitColumnsContainer> */}
    </div>
  </WorklistPopupComponent>
}
